import { useState } from 'react';

import {
  Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Spinner,
  Text,
} from '@chakra-ui/react';

import { fadeInUp, fadeOut } from '../../Animations';
import SignPad from '../SignPad/SignPad';

interface PDFSignDrawSignatureProps {
  onSign: (file: File) => Promise<any>;
  onComplete: () => void;
}

export default function PDFSignDrawSignature(props: PDFSignDrawSignatureProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [isEnterAnimation, setIsEnterAnimation] = useState(true);

  const _onComplete = (file: File) => {
    setIsEnterAnimation(false);
    setTimeout(() => {
      setIsLoading(true);
      props
        .onSign(file)
        .then(() => {
          setIsLoading(false);
          setIsEnterAnimation(false);
          props.onComplete();
        })
        .catch(() => {
          setIsLoading(false);
          setIsEnterAnimation(true);
        });
    }, 250);
  };

  return (
    <Drawer
      isOpen={true}
      closeOnOverlayClick={false}
      closeOnEsc={false}
      size={'sm'}
      placement={'bottom'}
      onClose={() => {}}
    >
      <DrawerOverlay
        bg={'url(https://mb-portail.s3.eu-west-3.amazonaws.com/images/backgrounds/bg-sign-blur.png) !important'}
        bgSize={'contain !important'}
      />
      <DrawerContent>
        <DrawerHeader>
          <Text textAlign={'center'} fontSize={'sm'} fontWeight={500}>
            Signez le document en dessinant votre signature dans l'encart ci-dessous
          </Text>
        </DrawerHeader>

        <DrawerBody pb={7}>
          <Flex w={'100%'} align={'center'} justify={'center'} direction={'column'}>
            {!isLoading && (
              <Box animation={isEnterAnimation ? `${fadeInUp} 0.5s ease` : `${fadeOut} 0.3s ease`}>
                <SignPad onComplete={(file) => _onComplete(file)} />
              </Box>
            )}

            {isLoading && (
              <Box animation={`${fadeInUp} 0.5s ease`}>
                <Spinner size={'lg'} />
              </Box>
            )}
          </Flex>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}
