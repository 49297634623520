import { useEffect, useState } from 'react';

import { useDisplayAfter } from '@meilleursbiens/hooks';

import { PinInputField } from '@chakra-ui/icons';
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  HStack,
  PinInput,
  Spinner,
  Text,
} from '@chakra-ui/react';

import { fadeIn, fadeInUp, fadeOutDown } from '../../Animations';

interface PDFSignSMSCodeProps {
  onValidationCode: (code: string) => Promise<any>;
  onSendCode: () => Promise<any>;
  onComplete: () => void;
}

export default function PDFSignSMSCode(props: PDFSignSMSCodeProps) {
  const { isDisplay, resetTimer } = useDisplayAfter(1000 * 10);

  const [isLoading, setIsLoading] = useState(false);
  const [isEnterAnimation, setIsEnterAnimation] = useState(true);

  const _onComplete = (code: string) => {
    setIsEnterAnimation(false);
    setTimeout(() => {
      setIsLoading(true);
      props
        .onValidationCode(code)
        .then(() => {
          setIsLoading(false);
          setIsEnterAnimation(false);
          props.onComplete();
        })
        .catch(() => {
          setIsLoading(false);
          setIsEnterAnimation(true);
        });
    }, 400);
  };

  useEffect(() => {
    props.onSendCode?.();
  }, []);

  return (
    <Drawer
      isOpen={true}
      closeOnOverlayClick={false}
      closeOnEsc={false}
      size={'sm'}
      placement={'bottom'}
      onClose={() => {}}
    >
      <DrawerOverlay
        bg={'url(https://mb-portail.s3.eu-west-3.amazonaws.com/images/backgrounds/bg-sign-blur.png) !important'}
        bgSize={'contain !important'}
      />
      <DrawerContent>
        <DrawerHeader>
          <Text textAlign={'center'} fontSize={'sm'} fontWeight={500}>
            Vous venez de recevoir un code de validation par SMS. Veuillez le saisir ci-dessous.
          </Text>
        </DrawerHeader>

        <DrawerBody pb={7}>
          <Flex w={'100%'} align={'center'} justify={'center'} direction={'column'}>
            {!isLoading && (
              <HStack animation={isEnterAnimation ? `${fadeInUp} 0.5s ease` : `${fadeOutDown} 0.5s ease`}>
                <PinInput size={'lg'} onComplete={(code) => _onComplete(code)} otp>
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                </PinInput>
              </HStack>
            )}

            {isLoading && (
              <Box animation={`${fadeInUp} 0.3s ease`}>
                <Spinner size={'lg'} />
              </Box>
            )}

            {isDisplay && (
              <Box mt={4} animation={`${fadeIn} 0.3s ease`}>
                <HStack>
                  <Text fontSize={'xs'} fontWeight={500} color={'muted'}>
                    Vous n'avez pas reçu de code ?{' '}
                  </Text>
                  <Text
                    fontSize={'xs'}
                    color={'red.500'}
                    textDecoration={'underline'}
                    fontWeight={'bold'}
                    cursor={'pointer'}
                    onClick={() => {
                      resetTimer();
                      props.onSendCode?.();
                    }}
                  >
                    Renvoyer le code
                  </Text>
                </HStack>
              </Box>
            )}
          </Flex>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}
