import { useCallback, useEffect, useState } from 'react';

export default function useDisplayAfter(time: number) {
  const [isTimePassed, setIsTimePassed] = useState(false);

  const resetTimer = useCallback(() => {
    setIsTimePassed(false);

    const timer = setTimeout(() => {
      setIsTimePassed(true);
    }, time);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsTimePassed(true);
    }, time);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return { isDisplay: isTimePassed, resetTimer };
}
