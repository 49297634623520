import { useState } from 'react';
import Lottie from 'react-lottie';

import { Drawer, DrawerBody, DrawerContent, DrawerHeader, DrawerOverlay, Text } from '@chakra-ui/react';

import { fadeInUp } from '../../Animations';
import animationData from './animation.json';

interface PDFSignSuccessProps {
  redirectUrl?: string;
}

export default function PDFSignSuccess(props: PDFSignSuccessProps) {
  const [isAnimationComplete, setIsAnimationComplete] = useState(false);

  return (
    <Drawer
      isOpen={true}
      closeOnOverlayClick={false}
      closeOnEsc={false}
      size={'sm'}
      placement={'bottom'}
      onClose={() => {}}
    >
      <DrawerOverlay
        bg={'url(https://mb-portail.s3.eu-west-3.amazonaws.com/images/backgrounds/bg-sign-blur.png) !important'}
        bgSize={'contain !important'}
      />
      <DrawerContent>
        <DrawerHeader></DrawerHeader>

        <DrawerBody pb={7}>
          <Lottie
            options={{
              loop: false,
              autoplay: true,
              animationData: animationData,
            }}
            eventListeners={[
              {
                eventName: 'complete',
                callback: () => {
                  if (props.redirectUrl) {
                    setTimeout(() => {
                      window.open(props.redirectUrl, '_self');
                    }, 1000);
                  } else {
                    setIsAnimationComplete(true);
                  }
                },
              },
            ]}
            height={200}
            width={200}
          />
          {isAnimationComplete && (
            <Text animation={`${fadeInUp} 0.4s ease`} fontSize={'sm'} textAlign={'center'} fontWeight={500}>
              Votre signature a bien été enregistrée, vous allez recevoir un email de confirmation !
            </Text>
          )}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}
