import React, { ReactElement } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';

import { useDisableCrisp } from '@meilleursbiens/hooks';
import { AppShell } from '@meilleursbiens/ui';

import AdminSearchWindowComponent from '../../Screens/Pro/Admin/AdminSearchWindowComponent';
import ImpersonateComponent from '../../UI/Impersonate/ImpersonateComponent';
import AdminSidebarLayoutComponent from './Sidebar/AdminSidebarLayoutComponent';

interface AdminSkeletonLayoutComponentProps {
  children: ReactElement;
}

export default function AdminSkeletonLayoutComponent(props: AdminSkeletonLayoutComponentProps) {
  const [isMobile, setIsMobile] = React.useState(false);
  const [searchWindow, setSearchWindow] = React.useState(false);

  useHotkeys(
    'alt+k, option+k, cmd+k, cmd+k, ctrl+k',
    (e) => {
      e.preventDefault();
      setSearchWindow(!searchWindow);
    },
    {
      keyup: false,
      keydown: true,
      enabled: true,
      enableOnTags: ['INPUT', 'TEXTAREA'],
    }
  );

  useDisableCrisp();

  return (
    <AppShell
      sidebar={
        <AdminSidebarLayoutComponent
          onSearch={() => setSearchWindow(!searchWindow)}
          onDeviceChange={(isMobile) => setIsMobile(isMobile)}
        />
      }
    >
      {React.cloneElement(props.children, { isMobile: isMobile })}

      <ImpersonateComponent />

      {searchWindow && (
        <AdminSearchWindowComponent
          onClose={() => {
            setSearchWindow(false);
          }}
        />
      )}
    </AppShell>
  );
}
