'use client';

import React from 'react';

import { Box, BoxProps } from '@chakra-ui/react';

export interface PDFViewerProps {
  src: string;
  fileName?: string;
  boxProps?: BoxProps;
  config?: any;
}

const PDFViewer = (props: PDFViewerProps) => {
  const configOverride = {
    showAnnotationTools: true,
    showLeftHandPanel: true,
    showPageControls: true,
    showDownloadPDF: true,
    showPrintPDF: true,
    ...props.config,
  };

  if (!props.src) return null;

  const randomPDFName = Math.random().toString(36).substring(7) + '.pdf';

  const loadPDF = () => {
    const viewSDKClient = new ViewSDKClient();
    viewSDKClient.ready().then(() => {
      viewSDKClient.previewFile('pdf-div', configOverride, props.src, props.fileName || randomPDFName);
    });
  };

  return (
    <Box
      position={'relative'}
      minH={'800px'}
      id={'pdf-div'}
      // @ts-ignore
      onDocumentLoad={loadPDF()}
      {...props.boxProps}
    ></Box>
  );
};

class ViewSDKClient {
  private adobeDCView: any;
  private readyPromise: Promise<void>;

  constructor() {
    this.readyPromise = new Promise((resolve) => {
      // @ts-ignore
      if (window.AdobeDC) {
        resolve();
      } else {
        document.addEventListener('adobe_dc_view_sdk.ready', () => {
          resolve();
        });
      }
    });
    this.adobeDCView = undefined;
  }

  ready() {
    return this.readyPromise;
  }

  previewFile(divId: any, viewerConfig: any, url: any, pdfName: string) {
    const isLocal = window.location.hostname === 'localhost';

    let config: any = {};
    if (isLocal) {
      config = {
        clientId: '6230705a3e344804994eb67040b35182',
        locale: 'fr-FR',
      };
    } else {
      config = {
        clientId: 'e9fd469644d043458b7a538eb2c77852',
        locale: 'fr-FR',
      };
    }

    if (divId) {
      config.divId = divId;
    }
    // @ts-ignore
    this.adobeDCView = new window.AdobeDC.View(config);
    const previewFilePromise = this.adobeDCView.previewFile(
      {
        content: {
          location: {
            url: url,
          },
        },
        metaData: {
          fileName: pdfName,
          id: '6d07d124-ac85-43b3-a867-36930f502ac6',
        },
      },
      viewerConfig
    );
    return previewFilePromise;
  }

  previewFileUsingFilePromise(divId: any, filePromise: any, fileName: any) {
    // @ts-ignore
    this.adobeDCView = new window.AdobeDC.View({
      clientId: 'e9fd469644d043458b7a538eb2c77852',
      divId,
    });
    this.adobeDCView.previewFile(
      {
        content: {
          promise: filePromise,
        },
        metaData: {
          fileName: fileName,
        },
      },
      {}
    );
  }

  registerSaveApiHandler() {
    const saveApiHandler = (metaData: any, content: any, options: any) => {
      console.log(metaData, content, options);
      return new Promise((resolve) => {
        setTimeout(() => {
          const response = {
            // @ts-ignore
            code: window.AdobeDC.View.Enum.ApiResponseCode.SUCCESS,
            data: {
              metaData: Object.assign(metaData, {
                updatedAt: new Date().getTime(),
              }),
            },
          };
          resolve(response);
        }, 2000);
      });
    };
    this.adobeDCView.registerCallback(
      // @ts-ignore
      window.AdobeDC.View.Enum.CallbackType.SAVE_API,
      saveApiHandler,
      {}
    );
  }

  registerEventsHandler() {
    this.adobeDCView.registerCallback(
      // @ts-ignore
      window.AdobeDC.View.Enum.CallbackType.EVENT_LISTENER,
      (event: any) => {
        console.log(event);
      },
      {
        enablePDFAnalytics: true,
      }
    );
  }
}

export default PDFViewer;
