import { Divider, Flex, HStack, Text, useColorMode, useColorModeValue, useMediaQuery } from '@chakra-ui/react';
import { MoonIcon, SunIcon } from '@heroicons/react/24/outline';

import IconButton from '../../Components/IconButton/IconButton';
import { Logo } from '../../Layouts';

export default function PDFSignHeader() {
  const { toggleColorMode, colorMode } = useColorMode();

  const [isMobile] = useMediaQuery('(max-width: 479px)');

  const bg = useColorModeValue('white', 'gray.700');

  return (
    <Flex
      position={'fixed'}
      top={0}
      left={0}
      right={0}
      w={'100vw'}
      zIndex={9999}
      align={'center'}
      dir={'row'}
      justifyContent={'space-between'}
      px={5}
      bg={bg}
      py={5}
      borderBottomWidth={1}
      boxShadow={'sm'}
    >
      <HStack spacing={3}>
        <Logo width={150} />
        {!isMobile && (
          <>
            <Divider orientation={'vertical'} h={'22px'} />
            <Text fontSize={'xs'} fontWeight={500} color={'muted'} noOfLines={1} textShadow={'md'}>
              Signez votre document en ligne
            </Text>
          </>
        )}
      </HStack>

      <HStack>
        {!isMobile && (
          <Text
            fontSize={'9px'}
            textTransform={'uppercase'}
            fontWeight={800}
            noOfLines={1}
            textShadow={'md'}
            color={'green.500'}
          >
            Espace sécurisé
          </Text>
        )}
        <IconButton
          icon={colorMode === 'dark' ? SunIcon : MoonIcon}
          aria-label={'Changer de mode de couleur'}
          onClick={toggleColorMode}
          size={'xs'}
          variant={'ghost'}
        />
      </HStack>
    </Flex>
  );
}
