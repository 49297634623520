import { useEffect, useState } from 'react';

import { useDisableCrisp } from '@meilleursbiens/hooks';
import { ToastUtils } from '@meilleursbiens/utils';

import { createStandaloneToast } from '@chakra-ui/react';

import { AppShell, LoadingPage } from '../../Layouts';
import cssStyles from './PDFSign.module.scss';
import PDFSignDrawSignature from './PDFSignDrawSignature';
import PDFSignHeader from './PDFSignHeader';
import PDFSignPDFViewer from './PDFSignPDFViewer';
import PDFSignSMSCode from './PDFSignSMSCode';
import PDFSignSuccess from './PDFSignSuccess';

const { ToastContainer } = createStandaloneToast();

export interface PDFSignProps {
  fetchPDF: () => Promise<string>;
  onSign: (file: File) => Promise<any>;
  onCodeValidation: (code: string) => Promise<any>;
  onSendCode: () => Promise<any>;
}

const PDFSign = (props: PDFSignProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [step, setStep] = useState(-1);
  const [pdfSrc, setPdfSrc] = useState<string | null>(null);

  useDisableCrisp();

  useEffect(() => {
    props
      .fetchPDF()
      .then((pdf) => {
        setPdfSrc(pdf);
        setStep(0);
      })
      .catch(() => {
        ToastUtils.showError('Erreur lors du chargement du PDF');
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <AppShell className={cssStyles.pdfSign} navbar={<PDFSignHeader />}>
      {isLoading && <LoadingPage />}

      {!isLoading && (
        <>
          {step === 0 && (
            <PDFSignPDFViewer
              src={pdfSrc || ''}
              onSign={() => {
                setStep(1);
              }}
            />
          )}
          {step === 1 && <PDFSignDrawSignature onSign={props.onSign} onComplete={() => setStep(2)} />}
          {step === 2 && (
            <PDFSignSMSCode
              onValidationCode={(code) => props.onCodeValidation?.(code)}
              onSendCode={props.onSendCode}
              onComplete={() => setStep(3)}
            />
          )}
          {step === 3 && <PDFSignSuccess />}
        </>
      )}
      <ToastContainer />
    </AppShell>
  );
};

export default PDFSign;
