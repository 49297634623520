import { useEffect, useState } from 'react';

const useKeyPress = (targetKey: string, isCtrlKeyNeeded?: boolean) => {
  const [keyPressed, setKeyPressed] = useState(false);

  useEffect(() => {
    const downHandler = (event: any) => {
      if (event.key === targetKey && (!isCtrlKeyNeeded || event.ctrlKey)) {
        setKeyPressed(true);
      }
    };

    const upHandler = (event: any) => {
      if (event.key === targetKey && (!isCtrlKeyNeeded || event.ctrlKey)) {
        setKeyPressed(false);
      }
    };

    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);

    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
  }, [targetKey]);

  return keyPressed;
};

export default useKeyPress;
