import { useDisplayAfter } from '@meilleursbiens/hooks';

import { Flex, useColorMode } from '@chakra-ui/react';
import { PencilSquareIcon } from '@heroicons/react/24/solid';

import { fadeInUp } from '../../Animations';
import Button from '../../Components/Button/Button';
import PDFViewer from '../PDFViewer/PDFViewer';

interface PDFSignPDFViewerProps {
  src: string;
  onSign: () => void;
}

export default function PDFSignPDFViewer(props: PDFSignPDFViewerProps) {
  return (
    <>
      <PDFViewer
        src={props.src}
        boxProps={{
          width: '100vw',
          height: 'calc(100vh - 20px)',
          position: 'fixed',
          top: '20px',
          left: 0,
          right: 0,
          zIndex: 1000,
        }}
        config={{
          showAnnotationTools: true,
          showLeftHandPanel: false,
          showPageControls: false,
          showDownloadPDF: true,
          showPrintPDF: true,
          showFullScreen: false,
          showThumbnails: false,
          showBookmarks: false,
        }}
      />

      <Flex
        animation={`${fadeInUp} 1s ease`}
        align={'center'}
        w={'100vw'}
        zIndex={9999}
        justifyContent={'center'}
        position={'fixed'}
        bottom={5}
      >
        <Button
          variant={'primary'}
          size={'xl'}
          borderRadius={'full'}
          leftIcon={PencilSquareIcon}
          boxShadow={'rgba(0, 0, 0, 0.2) 0px 20px 30px'}
          onClick={() => props.onSign()}
        >
          Signer le document
        </Button>
      </Flex>
    </>
  );
}
