import React from 'react';

import { Flex, Progress, useColorModeValue } from '@chakra-ui/react';

import { fadeInUp } from '../../Animations';
import { Logo } from '../Sidebar';

export interface LoadingPageProps {
  isFullParent?: boolean;
}

const LoadingPage = (props: LoadingPageProps) => {
  return (
    <Flex
      w={props.isFullParent ? '100%' : '100vw'}
      h={props.isFullParent ? '100%' : '100vh'}
      position={'relative'}
      justifyContent={'center'}
      direction={'column'}
      alignItems={'center'}
      bg={useColorModeValue('white', 'gray.800')}
      animation={`${fadeInUp} 0.8s ease`}
    >
      <Logo height={'23px'} />
      <Progress colorScheme={'red'} mt={8} isIndeterminate w={'130px'} h={'3px'} size={'xs'} />
    </Flex>
  );
};

export default LoadingPage;
